<template>
  <div class="bg-light private_job">
    <div class="col-md-12 p-0" v-if="jobDetails&&jobDetails.header_img">
        <img :src="$S3_bucket_url+jobDetails.header_img" alt="" style="width:100%">
    </div>
    <div class="title">
      <div class="container">
        <div class="col text-center mt-3">
          <marquee style="color:red;font-weight:bold;font-size:18px" v-if="expired">Applications for this recruitment are closed now.</marquee>
          <marquee style="color:red;font-weight:bold;font-size:18px" v-if="jobDetails&&jobDetails.marquee_text&&jobDetails.marquee_text.length&&!expired">{{jobDetails.marquee_text}}</marquee>
          <h3 class="mb-3" style="color: #24448f;font-weight: bold;" v-if="job&&job.job_designation">Job Title : {{job.job_designation}}</h3>
        </div>
      </div>
    </div>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 mt-3">
            <!-- <p class="text-left" v-if="jobrole"><b class="subhead">Job Role : {{jobrole.role}}</b> </p> -->
            <p class="text-left" v-if="job&&job.salary_range_from&&!job.salary_flag"><b class="subhead">CTC Offered (per annum) : <span>{{job.salary_range_from}}</span><span v-if="job.salary_range_to"> - {{job.salary_range_to}}</span></b> </p>
            <p class="text-left"><b class="subhead">Eligibility Criteria :</b></p>
            <ul>
              <li><span v-if="job&&job.courses"><span v-for="(course,index) in job.courses" :key="index">{{ course.name }}<span v-if="index < job.courses.length - 1">, </span></span></span>
                  <span v-if="job&&job.year_of_passing_from">with year of passing {{job.year_of_passing_from}}<span v-if="job&&job.year_of_passing_to"> - {{job.year_of_passing_to}}</span></span>
              </li>
              <li v-if="job&&job.hq_marks_percentage_from">Candidate should have scored minimum {{job.hq_marks_percentage_from}}% in Graduation, 12 th &amp; 10 th</li>
              <li v-if="job&&job.skills&&job.skills.length">Skills: <span v-for="(skill , index) in job.skills" :key="index">{{skill}}<span v-if="job.skills.length != index+1">,</span></span></li>
              <!-- <li>Candidate should not have any backlogs.</li> -->
            </ul>
            <p></p>
            <p class="text-left" v-if="job&&job.hiring_process"><b class="subhead">Selection Process : {{job.hiring_process}}</b> </p>
            <p class="text-left" v-if="cities&&cities.length"><b class="subhead">Job Location : <span v-for="(city, itemObjKey) in cities" :key="city.city_id">{{ city.city_name}}<span v-if="cities.length != itemObjKey + 1">,</span></span></b> </p>
            <!-- <p class="text-left" v-if="job&&job.job_desc_pdf"><b class="subhead">Job Description : </b><span><a :href="job.job_desc_pdf" target="_blank">Click here </a>to view detailed job description</span></p> -->
            <p  v-if="jobDetails&&jobDetails.job_desc_pdf"><b>Job Description :</b> <a :href="$S3_bucket_url+jobDetails.job_desc_pdf">Click here</a> to view detailed job description</p>
          </div>
          <div class="col-md-4" v-if="jobDetails&&jobDetails.job_video">
            <video style="width:100%;bottom:0;margin-top:10px;width:410px;" controls="">
              <source :src="$S3_bucket_url+jobDetails.job_video" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>
    <section class="applynow" v-if="job">
      <div class="">
        <div class="row ">
          <div class="col-sm text-center">
            <router-link :to="{path: '/form/'+$route.params.private_job}" class="btn btn-success text-white" style="font-size: 20px;" v-if="!success_alert">Apply now</router-link>
            <span v-else :class="alert_class">{{success_msg}}</span>
          </div>
        </div>
      </div>
    </section>
    <section  v-if="job&&job.job_description">
      <div class="container">
        <div class="row p-3">
          <div class="col-md-12">
            <div style="text-align: justify;" v-html="job.job_description"></div>
          </div>
        </div>
      </div>
    </section>
    <section>
        <div class="container">
            <div class="col text-center mt-5">
                <p style="color: #959595;"><small><a href="/">Hireclap.com</a> is the largest video resume-based job site in India. We publish job opportunities on behalf of hiring companies to share job openings with job seekers. The recruitment process will be conducted directly by the hiring companies. Please ensure that you review the job descriptions and eligibility criteria  before applying. Hireclap does not charge any fees for job offers. We strongly advise you to be cautious and beware of job offer scams.</small></p>
            </div>
        </div>
    </section>
    <section>
      <div class="row">
        <!--footer-->
        <div class="col" style="background-color: #b6b5b5;">
          <div class="container">
            <div class="row ">
              <div class="col-lg-5 alignmob">
                <!-- <p class="content-center" style="color: #fff;padding: 10px">© 2022 <a class="flink" style="color: white" href="">Hireclap</a> | Hire best matching candidates</p> -->
                <p class="content-center">© 2023 <a class="flink" target="_blank" href="https://www.hireclap.com/"><img height="50px" src="https://s3.ap-south-1.amazonaws.com/www.hireclap.com/Assets/images/hireclap-logo-HD.png" alt="Hireclap" title="Hireclap | Hire best matching candidates"></a></p>
              </div>
              <div class="col-lg-4 alignmob"><p class="content-center" style="padding-top: 15px;">
                <a href="" target="_blank">Made with <span style="color: #e74c3c">♥</span> in India</a>. </p>
              </div>
              <div class="col-lg-3 alignmob">
                <a href="https://www.facebook.com/HireClap"><v-icon class="mdi-footer mdi" >{{ $icons.facebook }}</v-icon></a>
                <a href="https://twitter.com/Hireclap"><v-icon class="mdi-footer mdi" >{{ $icons.twitter }}</v-icon></a>
                <a href="https://www.linkedin.com/company/hireclap"><v-icon class="mdi-footer mdi" >{{ $icons.linkedin }}</v-icon></a>
                <a href="https://www.youtube.com/channel/UC_Rhjfe3TniDTGLn8nxcu0Q"><v-icon class="mdi-footer mdi" >{{ $icons.youtube }}</v-icon></a>
                <a href="https://www.instagram.com/hireclap"><v-icon class="mdi-footer mdi" >{{ $icons.instagram}}</v-icon></a>
              </div>
            </div>
          </div>    
        </div>
        <!--footer end-->
      </div>
    </section>
  </div>
</template>

<script>
  import Actions from '../libraries/apiActions.js';
  import validation from '../libraries/validFormat' ;
  import '../assets/hire.css';
  export default {
    name: "recruiter_dashboard",
    metaInfo: (vue) => {
      const job = vue.job;
      const job_type = vue.c_job_type;
      const qualification = vue.qualification;
      const last_date_ts = vue.last_date_ts;
      const jobLocation = vue.jobLocation;
      const seo_title = vue.seo_title;
      const meta_desc = vue.meta_desc;
      const meta_keyword = vue.meta_keyword;

      return {
        title: seo_title ? seo_title : '',
        meta:[
          {name:'description',content: meta_desc ? meta_desc : ''},
          {name:'keywords',content: meta_keyword ? meta_keyword : ''},
        ],
        link: [
          {
            rel: "canonical",
            href: process.env.VUE_APP_URL + "jobs/" + vue.$route.params.job,
          },
        ],
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org/",
              "@type": "JobPosting",
              datePosted: `${job ? new Date(job.date_of_posting * 1000) : ""}`,
              validThrough: `${job ? new Date(last_date_ts * 1000) : ""}`,
              title: `${job ? job.job_designation : ""}`,
              description: `${job ? job.job_description : ""}`,
              employmentType: `${job_type ? job_type : ""}`,
              industry: "Private",
              qualifications: `${qualification ? qualification : ""}`,
              responsibilities: "-",
              hiringOrganization: {
                "@type": "Organization",
                name: `${job ? job.company_name : ""}`,
              },
              jobLocation: jobLocation,
              applicantLocationRequirements: {
                "@type": "Country",
                name: "India",
              },
              baseSalary: {
                "@type": "MonetaryAmount",
                currency: "INR",
                value: {
                  "@type": "QuantitativeValue",
                  minValue: `${job ? (job.salary_range_from ? job.salary_range_from : 0) : ""}`,
                  maxValue: `${job ? (job.salary_range_to ? job.salary_range_to : job.salary_range_from) : ""}`,
                  unitText: "monthly",
                },
              },
            },
          },
        ],
      };
    },
    async beforeMount() {
      let url = this.$route.path;
      let url_split = url.split("-");
      let id = url_split[url_split.length - 1];

      if (Object.keys(this.$route.params).length && this.$route.params.success) {
        this.success_alert = true;
        this.success_msg   = this.$route.params.success;
        this.alert_class   = this.$route.params.status?'text-success':'text-danger';
      } else {
        this.success_alert = false;
      }
      this.getJobDetails(id);
      await this.getCities();
      this.getQuestionnaire(id);
    },
    data() {
      return {
        modals: {
          open: "exit",
        },
        action: new Actions(),
        rec_jobs:[],
        jobs:[],
        seo_title: '',
        meta_desc: '',
        meta_keyword: '',
        filter:{
          job_role:null,
          city_id:null,
          offset: 0,
          limit:9
        },
        show: 4,
        load_more: 0,
        job_flag:0,
        total:0,
        limit:4,
        validate: new validation(),
        job: null,
        qualification: "",
        last_date_ts: null,
        jobLocation: [],
        courses: [],
        branches:[],
        cities: [],
        all_cities: [],
        jobrole: null,
        job_type: [
          { value: 1, text: "Full time" },
          { value: 2, text: "Work from home" },
          { value: 3, text: "Hybrid" },
          { value: 4, text: "Part time" },
          { value: 5, text: "Internship" },
          { value: 6, text: "Apprenticeship" },
        ],
        exp: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 2, text: "06 Months" },
          { value: 3, text: "1 Year" },
          { value: 4, text: "2 year" },
          { value: 5, text: "3 year" },
          { value: 6, text: "4 year" },
          { value: 7, text: "5 year" },
          { value: 8, text: "6 year" },
          { value: 9, text: "6+ year" },
        ],
        apply_job: {
          job_id: null,
          candidate_id: null,
          status: 0,
          applier_id: null,
          applier_type: "C",
          company_id: 1,
          src: null,
        },
        c_job_type: null,
        open: null,
        user: null,
        applied: false,
        questionnaire:[],
        hasQuestionnaire:false,
        botOptions:{
          botTitle: "Questionnaire"
        },
        isApply:false,
        botTyping: false,
        inputDisable: true,
        question_index:0,
        results: [],
        expired:null,
        loading:true,
        redirect_from: null,
        jobs_loading: true,
        gender:{
          options:[
            {value:1, text:'Male'},
            {value:2, text:'Female'},
            {value:3, text:'Both'}
          ]
        },
        error:false,
        err_msg:null,
        showDismissibleAlert:false,
        readmore:false,
        jobDetails: null,
        success_alert:false,
        success_msg  :'',
        alert_class  :''
      };
    },
    methods: {
      openModal: function (type = "") {
        this.open = type == "" ? this.open : type;
        if (this.open == "success") {
          this.$refs["apply-success"].show();
        }
        this.modals.open = "exit";
        this.modals.open = type;
      },
      getUser() {
        this.action.getUser(this.$cookies.get("hire-seeker").token).then((res) => {
          this.apply_job.candidate_id = res.user.id;
          this.apply_job.applier_id = res.user.id;
        });
      },
      getCities: async function () {
        await this.action.getCities().then(async (data) => {
          if(data.cities){
            await data.cities.forEach((element) => {
              this.all_cities.push({value: element.city_id,text: element.city_name});
            });
          }
        });
      }, 
      hasDupes(array, value) {
          return array.some(function (a) {
              return a.job_id == value
          });
      },
      getJobDetails(id) {
        this.action.getJobDetailsId({ id: id }).then((res) => {
          console.log(res);
          this.loading = false;
          this.jobDetails=res.data[0].custom;
          this.job = res.data[0];
          this.apply_job.job_id = this.job.job_id;
          this.last_date_ts = this.job.last_date;
          this.seo_title = this.job.seo_title;
          this.meta_desc = this.job.seo_description;
          this.meta_keyword = this.job.seo_keywords;
          this.job.last_date = this.validate.convertTimestamp(this.job.last_date);
          if(this.last_date_ts >= new Date(new Date().setHours(0, 0, 0, 0)).getTime()/1000){
            this.expired = false;
          }else{
            this.expired = true;
          }
          this.job.courses.forEach((course, i) => {
            this.qualification += course.name;
            if (this.job.courses.length != i + 1) {
              this.qualification += ",";
            }
            this.courses.push(course.name);
            course.branches.forEach((branch) => {
              this.branches.push(branch);
              
            })
          });
          this.loading = false;
          this.filter.city_id = this.getIdsfromNames(this.job.cities, this.all_cities);
          this.filter.job_role = this.job.job_role;
          this.job.cities.forEach((city) => {
            this.jobLocation.push({
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                addressLocality: city,
                addressRegion: city,
                streetAddress: city,
                postalCode: "-",
                addressCountry: "India",
              },
            });
          });
          this.getJobrole();
          this.getJobType();
        });
      },
      getJobDescription(job){
        let param = job.job_url?job.job_url:'';
        param = param.replace(/[^A-Z0-9]+/ig, "-");
        if(param.length > 80){
          let param_split = param.split("-");
          let id_length = param_split[param_split.length-1].length;
          param = param.substring(0, 80-(id_length+1));
          param = param+'-'+param_split[param_split.length-1];
        }
        
        window.open('/jobs/'+param, '_blank').focus();
      },
      getJobrole() {
        this.action.getJobrole().then((res) => {
          res.jobroles.forEach((role) => {
            if (role.role_id == this.job.job_role) {
              this.jobrole = role;
            }
          });
        });
      },
      getJobType() {
        this.job_type.forEach((type) => {
          if (type.value == this.job.job_type) {
            this.c_job_type = type.text;
          }
        });
      },
      getJobExp(exp1, exp2) {
        var display_exp = "";
        var experienceFrom = "";
        var experienceTo = "";

        if (exp1 == null) {
          experienceFrom = "0";
        } else if(exp1 == 0){
          experienceFrom = "0 Month";
        }else if (exp1 > 0 && exp1 < 12) {
          experienceFrom = exp1 + " Months";
        } else if (exp1 == 84) {
          experienceFrom = "6+ Years";
        } else {
          experienceFrom = exp1 / 12 + " Years";
        }

        if (exp2 == 0 || exp2 == null) {
          experienceTo = "0";
        } else if (exp2 > 0 && exp2 < 12) {
          experienceTo = exp2 + " Months";
        } else if (exp2 == 84) {
          experienceTo = "6+ Years";
        } else {
          experienceTo = exp2 / 12 + " Years";
        }


        if (experienceFrom == "0" && experienceTo == "0") {
          display_exp = "";
        } else if (exp2 == exp1) {
          display_exp = experienceTo;
        } else {
          display_exp = ((experienceFrom!=null)?experienceFrom:'') + ((experienceFrom!=null && experienceTo!="0"&&experienceTo!=null)?" to ":'') + (experienceTo!="0"&&experienceTo!=null?experienceTo:'');
        }
        return display_exp;
      },
      applyJob() {
        this.apply_job.src = this.$route.query.src? this.$route.query.src: "hire-job";
          if(!this.apply_job.src.includes('-jobid')){
            this.apply_job.src += '-jobid'+this.job.job_id;
          }
          this.error=false;
        if (this.$cookies.get("hire-seeker")) {
          this.action.applyJob(this.apply_job).then((res) => {
            if (res.status == 1) {
                if(this.hasQuestionnaire) {
                  this.isApply = true;
                  this.$router.push("/questionnaire/"+this.job.job_id,)
                }else{
                  this.openModal("success");
                  setTimeout(() => {
                    this.$refs["apply-success"].hide();
                  }, 3000);
                }
                this.applied = true;
            }
          }).catch(err => {
            console.log(err);
            this.error = true;
            if(window.navigator.onLine){
              this.err_msg = "System error! Please try again.";
            }else{
              this.err_msg = "Network error! Please check your internet connection.";
            }
          });
        } else {
          this.redirect_from = this.$route.path;
          let url = '/jobseeker/register?src='+this.apply_job.src+'&&redirect_from='+this.redirect_from;
          window.open(url, "_blank").focus();
        }
      },
      getIdsfromNames(names, arr){
        let res = [];
        names.forEach(name => {
          arr.forEach(item => {
            if(item["text"] == name) res.push(item["value"]);
            })
        })
        return res;
      },
      newTab(url) {
        if (!url.match(/^https?:\/\//i)) {
            url = "https://" + url;
        }
        if (this.$cookies.get("hire-seeker")) {
          window.open(url, "_blank").focus();
        } else {
          this.apply_job.src = this.$route.query.src? this.$route.query.src: "hire-job";
          if(!this.apply_job.src.includes('-jobid')){
            this.apply_job.src += '-jobid'+this.job.job_id;
          }
          this.redirect_from = url;
          let reg_url = '/jobseeker/register?src='+this.apply_job.src+'&&redirect_from='+this.redirect_from;
          window.open(reg_url, "_blank").focus();
        }
      },
      getQuestionnaire(id){
        this.action.existQuestionnaire(id).then(res => {
          if(res.status == true){
              this.hasQuestionnaire = true;
          }else{
            this.hasQuestionnaire = false;
          }
        })
      },
      async copyToClipboard(){
        await navigator.clipboard.writeText(window.location.href);
        this.showDismissibleAlert = true;
        setTimeout(() =>{
          this.showDismissibleAlert = false;
        },3000)
      },
      getGender(id){
        this.gender.options.forEach((g) => {
          if(g.value == id){
            this.user_gender = g.text;
          }
        })
        return this.user_gender;
      }
    }
  };
</script>

<style scoped>
  .title {
      border-bottom: solid 1px #e9e9e9;
  }
  .applynow {
    background: #f6f6f6;
    background-image: linear-gradient(40deg, #e6e6e6, #fdfdfd);
    padding: 15px 0px 10px 0px;
    margin: 30px 0px 30px 0px;
    font-size: 20px;
  }
  .footer {
    background: #fff;
    color: #000;
    box-shadow: 0 0 15px 0 #b4b4b4;
    padding-top: 15px;
    margin-top: 30px;
  }
</style>